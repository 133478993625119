<template>
  <div style="width: 100%;">
    <div v-if="this.levelOneLabel == null"
      style="display: flex; flex-direction: column; margin-bottom: 48px; width: 100%;">
      <button class="listOption" @click="selectlevelOne($event)" v-for="item in basel" :key="item.id">
        {{ item.label }}
      </button>
    </div>
    <div class="levelOneSelected body2" v-if="levelOneLabel != null"
      style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
      {{ this.levelOneLabel }}
      <button v-if="this.editMode == true" class="editButton" @click="editlevelOne">
        <i class="pi pi-pencil" style="font-weight: 700;"></i>
        Editar
      </button>
    </div>
    <div v-if="levelOneId != null && levelTwoLabel == null" style="width: 100%; display: flex; flex-direction: column;">
      <div v-for="item in basel" :key="item.id">
        <div style="display: flex; flex-direction: column;" v-if="item.id === levelOneId">
          <button class="listOption" style="padding: 20px 32px 20px 64px;" @click="selectlevelTwo($event, item)"
            v-for="child in item.children" :key="child.id">
            {{ child.label }}
          </button>
        </div>
      </div>
    </div>
    <div class="levelTwoSelected body2" v-if="levelTwoLabel != null && levelOneId != null"
      style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
      {{ this.levelTwoLabel }}
      <button v-if="this.editMode == true" class="editButton" @click="editlevelTwo">
        <i class="pi pi-pencil" style="font-weight: 700;"></i>
        Editar
      </button>
    </div>
    <div v-show="levelThreeLabel == null && levelTwoLabel != null && levelOneId != null && this.editMode == true"
      style="width: 100%; display: flex; flex-direction: column;">
      <button class="listOption" style="padding: 20px 32px 20px 96px;" @click="selectlevelThree($event, item.id)"
        v-for="item in levelThreeList" :key="item.id">
        {{ item.label }}
      </button>
    </div>
    <div class="levelThreeSelected body2" v-if="levelTwoLabel != null && levelOneId != null && levelThreeLabel != null"
      style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
      {{ this.levelThreeLabel }}
      <button v-if="this.editMode == true" class="editButton" @click="editlevelThree">
        <i class="pi pi-pencil" style="font-weight: 700;"></i>
        Editar
      </button>
    </div>
  </div>
</template>

<script>
import "../Global.css"
import FieldService from "../service/FieldService.js";

export default {
  data() {
    return {
      basel: null,
      levelOneLabel: null,
      levelOneId: null,
      levelTwoLabel: null,
      levelTwoId: null,
      levelThreeLabel: null,
      levelThreeId: null,
      levelThreeList: null,
    }
  },
  props: {
    baselLevel: Object,
    editMode: Boolean,
  },
  mounted() {
    this.basel = FieldService.getField('incident', 'baselLevel')
    this.localBaselLevel = this.baselLevel
    this.checkBaselFilled();
  },
  methods: {
    checkBaselFilled() {
      const { levelOne, levelTwo, levelThree } = this.baselLevel;

      if (!levelOne) return;

      // Encontrando o nível 1
      const levelOneItem = this.basel.find(item => item.id === levelOne);
      if (!levelOneItem) return;

      this.levelOneId = levelOne;
      this.levelOneLabel = levelOneItem.label;

      if (!levelTwo) return;

      // Encontrando o nível 2
      const levelTwoItem = levelOneItem.children.find(child => child.id === levelTwo);
      if (!levelTwoItem) return;

      this.levelTwoId = levelTwo;
      this.levelTwoLabel = levelTwoItem.label;

      this.findItemById(); 

      if (!levelThree) return;

      // Encontrando o nível 3
      const levelThreeItem = this.levelThreeList.find(item => item.id === levelThree);
      if (levelThreeItem) {
        this.levelThreeId = levelThree;
        this.levelThreeLabel = levelThreeItem.label;
      }
    },
    editlevelThree() {
      this.levelThreeLabel = null;
      this.levelThreeId = null;
      this.localBaselLevel.levelThree = this.levelThreeId;
      this.$emit('basel-level-change', this.localBaselLevel);
    },
    editlevelTwo() {
      this.levelTwoLabel = null;
      this.levelTwoId = null;
      this.localBaselLevel.levelTwo = this.levelTwoId;
      this.$emit('basel-level-change', this.localBaselLevel);
      this.editlevelThree();
    },
    editlevelOne() {
      this.levelOneLabel = null;
      this.levelOneId = null;
      this.localBaselLevel.levelOne = this.levelOneId;
      this.$emit('basel-level-change', this.localBaselLevel);
      this.editlevelTwo();
    },
    selectlevelOne(event) {
      this.levelOneLabel = event.target.textContent;
      this.basel.forEach((item) => {
        if (this.levelOneLabel === item.label) {
          this.levelOneId = item.id
        }
      })
      this.localBaselLevel.levelOne = this.levelOneId;
      this.$emit('basel-level-change', this.localBaselLevel);
    },
    selectlevelTwo(event, parent) {
      this.levelTwoLabel = event.target.textContent;
      parent.children.forEach((child) => {
        if (this.levelTwoLabel === child.label) {
          this.levelTwoId = child.id
        }
      })
      this.localBaselLevel.levelTwo = this.levelTwoId;
      this.$emit('basel-level-change', this.localBaselLevel);
      this.findItemById();
    },
    selectlevelThree(event, thirdId) {
      this.levelThreeLabel = event.target.textContent;
      this.levelThreeId = thirdId
      this.localBaselLevel.levelThree = this.levelThreeId
      this.$emit('basel-level-change', this.localBaselLevel);
    },
    findItemById() {
      const levelOne = this.basel.find(item => item.id === this.levelOneId);
      const levelTwo = levelOne.children.find(item => item.id === this.levelTwoId);
      this.levelThreeList = levelTwo.children;
    }
  },
};
</script>
<style>
.listOption {
  display: flex;
  justify-content: start;
  padding: 20px 32px 20px 32px;
  border-width: 0px 0px 1px 0px;
  border-color: var(--color_gray_xlight);
  background-color: transparent;
  text-justify: initial;
  cursor: pointer;
}

.levelOneSelected {
  display: flex;
  justify-content: start;
  padding: 20px 32px 20px 32px;
  border-width: 0px;
  background-color: var(--color_brand_medium);
  color: white;
  font-weight: 600;
  gap: 24px;
}

.editButton {
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  border-width: 0px;
  border-radius: 2px;
  background-color: white;
  color: var(--color_brand_medium);
  gap: 8px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.editButton:hover {
  background-color: var(--color_brand_xlight);
  color: var(--color_brand_xdark)
}

.levelTwoSelected {
  display: flex;
  justify-content: start;
  padding: 20px 32px 20px 64px;
  border-width: 0px;
  background-color: var(--color_brand_light);
  color: var(--color_brand_xdark);
  font-weight: 700;
  gap: 24px;
}

.levelThreeSelected {
  display: flex;
  justify-content: start;
  padding: 20px 32px 20px 96px;
  border-width: 0px;
  background-color: var(--color_brand_xlight);
  color: var(--color_brand_xdark);
  font-weight: 700;
  gap: 24px;
}
</style>